<div class="t-blog-section grid bg-white rounded-lg p-4 gap-4 mt-3">
  <div class="grid">
    <h4 class="text-gray-900 font-semibold text-xl">Explore places</h4>
    <div class="flex gap-1">
      <p class="text-gray-700">
        {{ prefix + "quantity-of-items" | translate }}
      </p>
      <p class="text-gray-400">
        ({{ explorePlacesSection?.items?.length || 0 }}/20)
      </p>
    </div>
  </div>

  <div class="grid gap-2">
    <div
      cdkDropList
      class="grid rounded-xl"
      (cdkDropListDropped)="drop($event)"
    >
      <div
        class="gird gap-3 p-1 rounded-xl"
        *ngFor="let explorePlace of explorePlacesSection?.items; let i = index"
        cdkDrag
      >
        <div class="flex h-[96px] gap-2 rounded-xl">
          <i class="sctr-icon-dots-grid"></i>
          <div class="flex bg-gray-200 rounded-xl border p-2 gap-3">
            <img
              class="w-[80px] h-[80px] rounded-xl object-cover"
              [src]="explorePlace?.image?.id! | image"
              alt="adventure style"
            />

            <p-dropdown
              [options]="places"
              [ngModel]="explorePlace"
              (ngModelChange)="updateExplore($event, i)"
              [filter]="true"
              [showClear]="true"
              optionLabel="title"
              filterBy="title"
              showFilterClear="true"
              filterPlaceholder="Search"
              showEmptyMessage="true"
              emptyMessage="No results found"
              [style]="{ width: '800px' }"
              (onFilter)="filterPlaces($event)"
            ></p-dropdown>
          </div>
          <div class="flex gap-2">
            <button
              (click)="removeExplore(i)"
              class="sctr-icon-trash-01 flex text-[20px]"
            ></button>
          </div>
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="explorePlacesSection && explorePlacesSection?.items?.length! < 20"
      class="grid gap-3"
    >
      <div class="flex h-[96px] gap-2 rounded-xl">
        <i class="sctr-icon-dots-grid"></i>
        <div class="flex bg-gray-200 rounded-xl border p-2 gap-3">
          <label
            *ngIf="!selectedFile"
            class="w-[80px] h-[80px] rounded-xl object-cover bg-white justify-center items-center flex"
            alt="adventure style"
            for="file-upload-explore-places"
          >
            <i
              class="w-11 h-11 sctr-icon-upload-cloud-02 text-[24px] text-blue-500 bg-blue-light-200 rounded-[50%] p-2 pl-[9px]"
            ></i>
            <input
              id="file-upload-explore-places"
              type="file"
              (change)="onFileSelected($event)"
              accept=".jpg, .jpeg, .png"
              hidden
            />
          </label>
          <img
            *ngIf="selectedFile"
            class="w-[80px] h-[80px] rounded-xl object-cover"
            [src]="selectedFile.id! | image"
            alt="adventure style"
          />
          <div class="grid gap-2">
            <p-dropdown
              [options]="places"
              [filter]="true"
              [showClear]="true"
              optionLabel="title"
              filterBy="title"
              [(ngModel)]="newItem"
              showFilterClear="true"
              placeholder="Select a place"
              filterPlaceholder="Search"
              showEmptyMessage="true"
              emptyMessage="No results found"
              [style]="{ width: '800px' }"
              (onFilter)="filterPlaces($event)"
            ></p-dropdown>
            <div class="flex justify-end">
              <button
                (click)="addExplore()"
                class="font-medium flex text-[14px] h-7 bg-white border border-blue-500 text-blue-500 rounded-lg justify-center items-center w-fit p-2 hover:bg-blue-light-25"
              >
                <span>{{ prefix + "add-item" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <button
            (click)="resetItem()"
            class="sctr-icon-trash-01 flex text-[20px]"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
