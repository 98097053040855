import { AppRoutes } from '@core/enum/routes.enum';
import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';
import { environment } from '@env/environment';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: SoctripIcons.TARGET_04,
    visible: true,
  },
  {
    path: AppRoutes.BRANCH_MANAGEMENT,
    title: AppRoutes.BRANCH_MANAGEMENT,
    icon: SoctripIcons.BUILDING_02,
    visible: true,
  },
  {
    path: AppRoutes.BOOKING_HELPDESK,
    title: AppRoutes.BOOKING_HELPDESK,
    icon: SoctripIcons.FILE_SEARCH_02,
    visible: true,
  },
  {
    path: AppRoutes.REFUND_MANAGEMENT,
    title: AppRoutes.REFUND_MANAGEMENT,
    icon: SoctripIcons.CREDIT_CARD_REFRESH,
    visible: true,
  },
  {
    path: AppRoutes.VOUCHER_MANAGEMENT,
    title: AppRoutes.VOUCHER_MANAGEMENT,
    icon: SoctripIcons.TICKET_01,
    visible: true,
  },
  {
    path: AppRoutes.BANNER_MANAGERMENT,
    title: AppRoutes.BANNER_MANAGERMENT,
    icon: SoctripIcons.IMAGE_INDENT_LEFT,
    visible: true,
  },
  {
    path: AppRoutes.CHAT_FAQ_ASSISTANT,
    title: AppRoutes.CHAT_FAQ_ASSISTANT,
    icon: SoctripIcons.MESSAGE_QUESTION_SQUARE,
    visible: environment.SOCCHAT_VISIBLE,
  },
  {
    path: AppRoutes.PROMOTION_MANAGEMENT,
    title: AppRoutes.PROMOTION_MANAGEMENT,
    icon: SoctripIcons.PERCENT_03,
    visible: environment.PROMOTION_MANAGEMENT_VISIBLE,
  },
  {
    path: AppRoutes.TOUR_MANAGEMENT,
    title: AppRoutes.TOUR_MANAGEMENT,
    icon: SoctripIcons.CUBE_01,
    visible: environment.TOUR_MANAGEMENT_VISIBLE,
  },
  {
    path: '',
    title: 'configuration',
    icon: SoctripIcons.LAYOUT_ALT_03,
    children: [
      {
        path: AppRoutes.LANDING_PAGE_CONTENT,
        title: AppRoutes.LANDING_PAGE_CONTENT,
        icon: '',
        visible: true,
      },
    ],
    visible: true,
  },
];
