import { FormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import {
  FileDTO,
  LPSectionDTO,
  LPSectionItemDTO,
} from '@assistant/angular-travel-admin-service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
})
export class HeroSectionComponent {
  constructor(private httpClient: HttpClient) {}
  @Input() heroSection: LPSectionDTO | undefined;
  prefix: string = 'landing-page-content.';
  heading = new FormControl();
  selectedFile: FileDTO | undefined;
  uploading = false;
  ngOnChanges() {
    if (this.heroSection) {
      console.log('this.heroSection.heading', this.heroSection.heading);
      this.heading.setValue(this.heroSection.heading);
      this.selectedFile = this.heroSection.items?.[0].image as
        | FileDTO
        | undefined;
    }
  }

  onFileSelected($event: any) {
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.uploading = true;
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.uploading = false;
            this.selectedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };

            if (this.heroSection) {
              this.heroSection.items = [
                {
                  image: this.selectedFile,
                },
              ];
            }
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {},
      });
  }
  inputHeading() {
    if (!this.heroSection) {
      return;
    }
    this.heroSection.heading = this.heading.value;
    console.log(this.heroSection);
  }
  removeFile() {
    this.selectedFile = undefined;
  }
}
