import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { FileDTO, LPSectionDTO } from '@assistant/angular-travel-admin-service';
import { FormControl } from '@angular/forms';
import { DialogConfig } from '@core/models/interfaces/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';
import { CustomTranslateService } from '@core/services/custom-translate.service';
@Component({
  selector: 'app-adventure-styles',
  templateUrl: './adventure-styles.component.html',
  styleUrls: ['./adventure-styles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdventureStylesComponent {
  isOpenDialog = false;

  removeFile() {
    throw new Error('Method not implemented.');
  }
  @Input() adventureStylesSection: LPSectionDTO | undefined;
  prefix: string = 'landing-page-content.';

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}
  heading = new FormControl();
  addNewHeading = new FormControl();
  content = new FormControl();
  dialogConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'w-[588px]',
    appendTo: 'body',
  };
  ngOnChanges() {
    if (this.adventureStylesSection) {
      this.heading.setValue(this.adventureStylesSection.heading);
      this.content.setValue(this.adventureStylesSection.content);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.adventureStylesSection?.items!,
      event.previousIndex,
      event.currentIndex,
    );
  }
  inputHeading() {
    if (!this.adventureStylesSection) {
      return;
    }
    this.adventureStylesSection.heading = this.heading.value;
  }
  inputContent() {
    if (!this.adventureStylesSection) {
      return;
    }
    this.adventureStylesSection.content = this.content.value;
  }
  removeAdventureStyle(index: number) {
    this.adventureStylesSection?.items?.splice(index, 1);
  }

  selectedFile: FileDTO | undefined;
  onFileSelected($event: any) {
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.selectedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {},
      });
  }

  saveNewAdventure() {
    if (!this.selectedFile || !this.addNewHeading.value) {
      this.addNewHeading.markAsTouched();
      return;
    }
    if (this.adventureStylesSection && !this.adventureStylesSection.items) {
      this.adventureStylesSection.items = [];
    }
    this.adventureStylesSection?.items?.push({
      image: this.selectedFile,
      title: this.addNewHeading.value,
      is_active: false,
    });
    this.messageService.add({
      severity: 'success',
      summary: this.translator.transform(this.prefix + 'success'),
      detail: this.translator.transform(
        this.prefix + 'new-adventure-style-added-successfully',
      ),
    });
    this.isOpenDialog = false;
    this.addNewHeading.setValue('');
    this.selectedFile = undefined;
  }
  closeDialog() {
    this.isOpenDialog = false;
  }

  openDialog() {
    this.isOpenDialog = true;
  }
}
