<div class="t-blog-section grid bg-white rounded-lg p-4 gap-4 mt-3">
  <div class="grid">
    <h4 class="text-gray-900 font-semibold text-xl">Adventure styles</h4>
    <p class="text-[14px] text-gray-700">
      {{ prefix + "quantity-of-items" | translate }}
    </p>
  </div>

  <div class="grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1"
      >{{ prefix + "heading" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">
        ({{ adventureStylesSection?.heading?.length || 0 }}/30)
      </p></span
    >
    <input
      class="w-full border border-gray-300 rounded-md p-2"
      type="text"
      placeholder="{{ prefix + 'enter-heading' | translate }}"
      [formControl]="heading"
      (input)="inputHeading()"
      required
    />
    <span
      *ngIf="heading?.errors?.['required'] && heading.touched"
      class="text-error-500 text-sm"
    >
      {{ prefix + "heading-is-required" | translate }}.
    </span>
  </div>
  <div class="t-description grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1">
      {{ prefix + "description" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">
        ({{ adventureStylesSection?.content?.length }}/400)
      </p></span
    >
    <textarea
      class="w-full border border-gray-300 rounded-md p-2"
      placeholder="{{ prefix + 'enter-description' | translate }}"
      rows="4"
      [formControl]="content"
      (input)="inputContent()"
    ></textarea>
  </div>
  <div class="grid gap-2">
    <div class="flex justify-between">
      <span class="text-gray-900 font-medium text-[14px] flex gap-1"
        >Adventure styles management
        <p class="text-error-500">*</p>
        <p class="text-gray-400">
          ({{ adventureStylesSection?.items?.length || 0 }}/20)
        </p>
      </span>
      <button
        class="bg-blue-600 rounded-lg text-white p-2 text-[14px] align-middle content-center flex gap-1"
        [ngClass]="
          adventureStylesSection?.items?.length === 20
            ? 'bg-gray-300'
            : 'bg-blue-600 hover:bg-blue-500'
        "
        (click)="openDialog()"
        [disabled]="adventureStylesSection?.items?.length === 20"
      >
        <i class="sctr-icon-plus-circle pt-1"></i>
        {{ prefix + "add-item" | translate }}
      </button>
    </div>
    <div
      cdkDropList
      class="grid rounded-xl"
      (cdkDropListDropped)="drop($event)"
    >
      <div
        class="gird gap-3 p-1 rounded-xl"
        *ngFor="
          let adventureStyle of adventureStylesSection?.items;
          let i = index
        "
        cdkDrag
      >
        <div class="flex h-[96px] gap-2 rounded-xl">
          <i class="sctr-icon-dots-grid"></i>
          <div class="flex gap-1 bg-gray-200 rounded-xl border p-2">
            <img
              class="w-[160px] h-[80px] rounded-xl object-cover"
              [src]="adventureStyle.image?.id! | image"
              alt="adventure style"
            />
            <textarea
              class="w-[700px] h-[80px] border border-gray-300 rounded-md p-2"
              placeholder="Enter description"
              rows="4"
              [value]="adventureStyle.title"
            ></textarea>
          </div>
          <div class="flex gap-3">
            <p-inputSwitch
              [(ngModel)]="adventureStyle.is_active"
              class="flex"
            ></p-inputSwitch>
            <button
              (click)="removeAdventureStyle(i)"
              class="sctr-icon-trash-01 flex text-[20px]"
            ></button>
          </div>
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  [showDialog]="isOpenDialog"
  [dialogConfig]="dialogConfig"
  (emitOnSaveDialog)="saveNewAdventure()"
  (emitOnCancelDialog)="closeDialog()"
>
  <div modal-header class="flex gap-2 items-center">
    <h4 class="text-gray-900 font-medium text-xl">Add new adventure styles</h4>
  </div>
  <div modal-content class="grid gap-3">
    <div class="grid gap-2">
      <span class="text-gray-900 font-medium text-[14px] flex gap-1"
        >Title
        <p class="text-error-500">*</p>
        <p class="text-gray-400">
          ({{ addNewHeading.value?.length || 0 }}/30)
        </p></span
      >
      <input
        class="w-full border border-gray-300 rounded-md p-2"
        type="text"
        placeholder="Enter heading"
        [formControl]="addNewHeading"
        maxlength="30"
        required
      />
      <span
        *ngIf="addNewHeading.errors?.['required'] && addNewHeading.touched"
        class="text-error-500 text-sm"
      >
        {{ prefix + "heading" | translate }}
      </span>
    </div>
    <div class="grid gap-2">
      <div class="grid gap-2">
        <span class="text-gray-900 font-medium text-[14px] flex gap-1"
          >{{ prefix + "upload" | translate }} media
          <p class="text-error-500">*</p>
        </span>
      </div>
      <div *ngIf="selectedFile" class="grid gap-2">
        <img
          class="w-[300px] h-[150px] rounded-xl object-cover"
          [src]="selectedFile.id! | image"
          alt="adventure style"
        />
        <div class="flex gap-1 justify-between">
          <input
            id="file-upload-new-adventure-styles"
            type="file"
            (change)="onFileSelected($event)"
            accept=".jpg, .jpeg, .png"
            hidden
          />
          <label
            for="file-upload-new-adventure-styles"
            class="bg-white text-gray-900 h-10 border rounded-lg w-20 font-semibold cursor-pointer flex items-center justify-center"
          >
            {{ prefix + "replace" | translate }}
          </label>
        </div>
      </div>
      <div
        *ngIf="!selectedFile"
        class="h-[120px] rounded-2xl border border-gray-300 grid items-center"
      >
        <label
          for="file-upload-new-adventure-styles"
          class="flex flex-col items-center mt-3"
        >
          <!-- <span class="w-10 h-10 rounded-[50%] bg-gray-400"> -->
          <i class="sctr-icon-upload-cloud-02 text-[24px] text-gray-500"></i>

          <span
            class="text-gray-500 text-[14px] flex gap-1 hover:cursor-pointer"
            ><p class="text-blue-500">
              {{ prefix + "click-to-upload" | translate }}
            </p>
            {{ prefix + "or-drag-and-drop" | translate }}</span
          >
          <input
            id="file-upload-new-adventure-styles"
            type="file"
            (change)="onFileSelected($event)"
            accept=".jpg, .jpeg, .png, .mp4"
            hidden
          />
        </label>
        <p class="flex flex-col items-center text-gray-400 text-[14px]">
          Accepts MP4, JPG or PNG
        </p>
      </div>
      <p
        class="text-[14px] text-gray-500 bg-gray-100 rounded-xl px-2 w-full h-7 justify-start items-center flex gap-2"
      >
        <i class="sctr-icon-info-circle"></i>
        {{ prefix + "recommended-image-ratios-add" | translate }}
      </p>
    </div>
  </div>
  <div modal-footer class="justify-end flex gap-2">
    <button
      class="bg-white rounded-lg text-gray900 w-24 hover:bg-gray-100 p-2 text-[14px] align-middle content-center justify-center flex gap-1 border border-gray-400"
      (click)="closeDialog()"
    >
      {{ prefix + "cancel" | translate }}
    </button>
    <button
      [ngClass]="
        addNewHeading.invalid || !selectedFile
          ? 'bg-gray-300'
          : 'bg-blue-600 hover:bg-blue-500'
      "
      [disabled]="addNewHeading.invalid || !selectedFile"
      class="rounded-lg text-white p-2 text-[14px] align-middle content-center flex gap-1 w-20 justify-center items-center"
      (click)="saveNewAdventure()"
    >
      {{ prefix + "save" | translate }}
    </button>
  </div>
</app-modal>
