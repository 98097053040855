<div class="t-hero-section grid bg-white rounded-lg p-4 gap-4">
  <h4 class="text-gray-900 font-semibold text-xl">Hero section</h4>
  <div class="grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1"
      >{{ prefix + "heading" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">
        ({{ heroSection?.heading?.length || 0 }}/100)
      </p></span
    >
    <input
      class="w-full border border-gray-300 rounded-md p-2"
      type="text"
      placeholder="{{ prefix + 'enter-heading' | translate }}"
      maxlength="100"
      [formControl]="heading"
      (input)="inputHeading()"
      required
    />
    <span
      *ngIf="heading?.errors?.['required'] && heading.touched"
      class="text-error-500 text-sm"
    >
      {{ prefix + "heading-is-required" | translate }}.
    </span>
  </div>
  <div class="grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1"
      >Hero media
      <p class="text-error-500">*</p>
    </span>
    <div *ngIf="this.selectedFile" class="grid gap-1">
      <img
        class="w-[968px] h-[544px] rounded-xl object-cover"
        [src]="selectedFile.id! | image"
        alt="adventure style"
      />
      <div class="flex gap-1 justify-between">
        <input
          id="file-upload"
          type="file"
          (change)="onFileSelected($event)"
          accept=".jpg, .jpeg, .png"
          hidden
        />
        <label
          for="file-upload"
          class="bg-white text-gray-900 h-10 border rounded-lg w-[50%] font-semibold cursor-pointer flex items-center justify-center"
        >
          {{ prefix + "upload" | translate }}
        </label>
        <button
          class="bg-white text-gray-900 border rounded-lg w-[50%] font-semibold"
          (click)="removeFile()"
        >
          {{ prefix + "remove" | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="!this.selectedFile"
      class="h-[120px] rounded-2xl border border-gray-300 grid items-center"
    >
      <label for="file-upload" class="flex flex-col items-center mt-3">
        <!-- <span class="w-10 h-10 rounded-[50%] bg-gray-400"> -->
        <i class="sctr-icon-upload-cloud-02 text-[24px] text-gray-500"></i>

        <span
          *ngIf="!uploading"
          class="text-gray-500 text-[14px] flex gap-1 hover:cursor-pointer"
          ><p class="text-blue-500">
            {{ prefix + "click-to-upload" | translate }}
          </p>
          {{ prefix + "or-drag-and-drop" | translate }}</span
        >
        <span *ngIf="uploading" class="text-gray-500 text-[14px]">{{
          prefix + "uploading" | translate
        }}</span>
        <input
          id="file-upload"
          type="file"
          (change)="onFileSelected($event)"
          accept=".jpg, .jpeg, .png"
          hidden
        />
      </label>
      <p class="flex flex-col items-center text-gray-400 text-[14px]">
        Accepts JPG or PNG
      </p>
    </div>
    <p
      class="text-[14px] text-gray-500 bg-gray-100 rounded-xl px-2 w-full h-7 justify-start items-center flex gap-2"
    >
      <i class="sctr-icon-info-circle"></i>
      {{ prefix + "recommended-image-ratios-hero" | translate }}
    </p>
  </div>
</div>
