import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateString',
  standalone: true,
})
export class TruncateStringPipe implements PipeTransform {
  transform(value: string, limitChar: number) {
    if (!value) {
      return null;
    }
    return value && value.length > limitChar
      ? value.slice(0, limitChar - 1) + '...'
      : value;
  }
}
