import { MessageService } from 'primeng/api';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  LPSectionDTO,
  LPSectionUpdateDTO,
  LandingPageMngtControllerService,
} from '@assistant/angular-travel-admin-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { CustomTranslateService } from '@core/services/custom-translate.service';
@Component({
  selector: 'app-landing-page-content',
  templateUrl: './landing-page-content.component.html',
  styleUrls: ['./landing-page-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageContentComponent implements OnInit {
  countries: any;
  selectedCountry: any;
  selectLang: any;
  prefix: string = 'landing-page-content.';
  constructor(
    private landingPageService: LandingPageMngtControllerService,
    private messageService: MessageService,
    private http: HttpClient,
    private translator: CustomTranslateService,
  ) {}

  landingPageContent:
    | {
        [key: string]: LPSectionDTO;
      }
    | undefined = {};

  heroSection: LPSectionDTO | undefined;
  blogSection: LPSectionDTO | undefined;
  adventureStylesSection: LPSectionDTO | undefined;
  explorePlacesSection: LPSectionDTO | undefined;

  ngOnInit() {
    this.fetchCountryListAndGetAllLPSection();
  }
  private getAllLPSection() {
    this.landingPageService
      .getAllLPSection(
        this.selectedCountry.country_code.toLowerCase(),
        this.selectedCountry.country_code.toLowerCase(),
      )
      .subscribe({
        next: (res) => {
          this.landingPageContent = res.data;
          this.heroSection = res.data ? res.data['Hero'] : undefined;
          this.blogSection = res.data ? res.data['Introduction'] : undefined;
          this.adventureStylesSection = res.data
            ? res.data['AdventureStyle']
            : undefined;
          this.explorePlacesSection = res.data
            ? res.data['ExplorePlace']
            : undefined;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  save() {
    const body: LPSectionUpdateDTO[] = [
      this.heroSection,
      this.blogSection,
      this.adventureStylesSection,
      this.explorePlacesSection,
    ].filter((section) => section !== undefined) as LPSectionUpdateDTO[];
    this.messageService.add({
      severity: 'success',
      summary: this.translator.transform(this.prefix + 'saving'),
      detail: this.translator.transform(this.prefix + 'saving-data'),
      icon: 'sctr-icon-refresh-ccw-03 animate-spin',
      life: 100000,
    });
    this.landingPageService
      .updateLPSectionList(
        body,
        this.selectedCountry.country_code.toLowerCase(),
      )
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.messageService.clear();
            this.messageService.add({
              severity: 'success',
              summary: this.translator.transform(this.prefix + 'success'),
              detail: this.translator.transform(
                this.prefix + 'data-saved-successfully',
              ),
            });
          }
        },
        error: (err) => {
          console.log(err);
          this.messageService.add({
            severity: 'error',
            summary: this.translator.transform(this.prefix + 'error'),
            detail: this.translator.transform(
              this.prefix + 'an-error-occurred-please-try-again-later',
            ),
          });
        },
      });
  }
  preview() {
    throw new Error('Method not implemented.');
  }
  changeLanguage($event: any) {
    this.getAllLPSection();
  }
  fetchCountryListAndGetAllLPSection() {
    const params = new HttpParams().set('validCountry', true);
    this.http
      .get(`${environment.BASE_API_URL}/regional/regional/country`, {
        params,
      })

      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.countries = res.data;
            this.selectedCountry = res.data[1];
            this.selectLang = res.data[1];
            this.getAllLPSection();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
