import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { PermissionService } from '@core/services/permission/permission.service';
import { UserService } from '@core/services/user/user.service';
import { AvatarService } from '@shared/services/avatar.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { environment as localEnv } from 'src/environments/environment.localhost';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  stateView: string = STATE_RENDER_APP.APP;
  user: any;
  userRole = [];

  private tokenStorageService = inject(TokenStorageService);
  private userService = inject(UserService);
  private permissionService = inject(PermissionService);
  private avatarService = inject(AvatarService);
  private router = inject(Router);
  private auth = inject(AuthGuard);

  constructor() {
    window.addEventListener('message', this.handleReceiveMessage);
  }

  ngOnInit() {
    this.connectPMServer();
    // const user:any = localStorage.getItem('auth')
    // this.user=JSON.parse(user)
    // this.user.avatarURL = `${environment.BE_URL}storage/files/web/${this.user?.avatar_thumbnail_url}.webp`;
    this.checkSmallScreen();
    window.addEventListener('resize', () => this.checkSmallScreen());
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = environment.IFRAME_CONNECT_URL;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  handleReceiveMessage = async (e: MessageEvent) => {
    const currentUrl = window.location.href;
    if (
      e?.data?.type === 'authorized' &&
      e.origin === environment.ADMIN_HUB_URL
    ) {
      if (currentUrl.includes('localhost')) {
        const accessToken = localStorage.getItem(environment.TOKEN_KEY);
        if (!accessToken) this.router.navigate(['/login']);
      } else {
        const accessTokenData = e.data?.token?.accessToken;
        const profileData = JSON.parse(e.data?.profile);
        if (accessTokenData && profileData) {
          this.tokenStorageService.saveToken(accessTokenData);
          const hasPermission =
            await this.permissionService.checkPermission(accessTokenData);
          if (hasPermission) {
            this.user = profileData;
            this.stateView = STATE_RENDER_APP.APP;
            this.userService.saveFrofile(JSON.stringify(profileData));
            this.user.avatarURL = `${environment.BE_URL}storage/files/web/${this.user?.avatar_thumbnail_url}.webp`;
            localStorage.setItem(
              environment.AUTH_STATUS,
              JSON.stringify({ isLoggedIn: true }),
            );
            this.avatarService.getAvatar(this.user?.avatar_thumbnail_url);
          } else {
            localStorage.clear();
            window.location.href = `${environment.ADMIN_HUB_URL}`;
          }
        } else {
          window.location.href = `${environment.ADMIN_HUB_URL}`;
        }
      }
    } else if (['unauthorized', 'success'].includes(e.data.type)) {
      localStorage.clear();
      window.location.href = `${environment.ADMIN_HUB_URL}/login`;
      // Do something like show login button or suggest login
    }
  };

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }
}
