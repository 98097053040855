import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image',
  standalone: true,
})
export class ImagePathPipe implements PipeTransform {
  transform(
    file: string,
    option: 'web' | 'thumbnail' | 'mobile' = 'web',
  ): string {
    if (!file) {
      return '/assets/imgs/default/image.webp';
    }
    if (file.includes('/')) {
      return `${environment['baseURL']}${file}`;
    }
    return `${environment['baseURL']}/storage/files/${option}/${file}.webp`;
  }
}
