<div class="t-blog-section grid bg-white rounded-lg p-4 gap-4 mt-3">
  <h4 class="text-gray-900 font-semibold text-xl">Blog section</h4>
  <div class="grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1"
      >{{ prefix + "heading" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">
        ({{ blogSection?.heading?.length || 0 }}/100)
      </p></span
    >
    <input
      class="w-full border border-gray-300 rounded-md p-2"
      type="text"
      placeholder="{{ prefix + 'enter-heading' | translate }}"
      max="100"
      [formControl]="heading"
      (input)="inputHeading()"
      required
    />
    <span
      *ngIf="heading?.errors?.['required'] && heading.touched"
      class="text-error-500 text-sm"
    >
      {{ prefix + "heading-is-required" | translate }}.
    </span>
  </div>
  <div class="t-description grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1">
      {{ prefix + "description" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">
        ({{ blogSection?.content?.length || 0 }}/400)
      </p></span
    >
    <textarea
      class="w-full border border-gray-300 rounded-md p-2"
      placeholder="{{ prefix + 'enter-description' | translate }}"
      rows="4"
      [maxLength]="400"
      [formControl]="content"
      (input)="inputContent()"
      required
    ></textarea>
    <span
      *ngIf="content?.errors?.['required'] && content.touched"
      class="text-error-500 text-sm"
    >
      {{ prefix + "description-is-required" | translate }}.
    </span>
  </div>
  <div class="grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1"
      >Blog media
      <p class="text-error-500">*</p>
    </span>
    <div class="grid gap-2">
      <div *ngIf="selectedFiles && selectedFiles.length > 0" class="grid gap-2">
        <div class="flex gap-3 max-w-[968px]">
          <p-carousel
            [value]="selectedFiles"
            [numVisible]="4"
            [numScroll]="3"
            [circular]="false"
            [responsiveOptions]="responsiveOptions"
            class="w-[968px] h-[204px]"
          >
            <ng-template let-selectedFile pTemplate="item">
              <div
                class="w-[166px] h-[204px] rounded-lg p-1 border border-gray-300 grid gap-1 items-center justify-center"
              >
                <div class="relative inline-block">
                  <img
                    class="w-[150px] h-[100px] rounded-md object-cover"
                    [src]="
                      (selectedFile.type.includes('image')
                        ? selectedFile.id
                        : ''
                      ) | image
                    "
                    alt="blogSection"
                  />
                  <button
                    class="sctr-icon-x-close text-white text-[24px] font-medium absolute top-0 right-0 m-1"
                    (click)="removeFile(selectedFile)"
                  ></button>
                </div>
                <span class="text-gray-700 text-[14px] font-medium">{{
                  selectedFile.original_name | truncateString: 15
                }}</span>
                <!-- <span class="text-gray-400 font-normal text-[14px]"
                >{{ selectedFile.size }}KB</span
              > -->

                <input
                  id="file-upload-replace-{{ selectedFile.id }}"
                  type="file"
                  (change)="onFileSelectedReplace($event, selectedFile)"
                  accept=".jpg, .jpeg, .png, .mp4"
                  hidden
                />
                <label
                  for="file-upload-replace-{{ selectedFile.id }}"
                  class="w-[150px] h-[36px] text-gray-900 font-medium border border-gray-400 rounded-lg flex items-center justify-center hover:cursor-pointer"
                >
                  {{ prefix + "replace" | translate }}
                </label>
              </div>
            </ng-template>
          </p-carousel>
        </div>
        <div class="flex gap-1 justify-end">
          <input
            id="file-upload-more"
            type="file"
            (change)="onFileSelected($event)"
            accept=".jpg, .jpeg, .png"
            hidden
            multiple
          />
          <label
            for="file-upload-more"
            class="bg-blue-600 hover:bg-blue-500 text-white h-10 border rounded-lg w-24 font-semibold cursor-pointer flex items-center justify-center gap-1"
          >
            <i
              [ngClass]="
                uploading ? 'sctr-icon-refresh-ccw-03 animate-spin' : ''
              "
            ></i>
            <span>{{ prefix + "upload" | translate }}</span>
          </label>
        </div>
      </div>

      <div
        *ngIf="!selectedFiles || selectedFiles.length === 0"
        class="h-[120px] rounded-2xl border border-gray-300 grid items-center"
      >
        <label for="file-uploads" class="flex flex-col items-center mt-3">
          <!-- <span class="w-10 h-10 rounded-[50%] bg-gray-400"> -->
          <i class="sctr-icon-upload-cloud-02 text-[24px] text-gray-500"></i>

          <span
            *ngIf="!uploading"
            class="text-gray-500 text-[14px] flex gap-1 hover:cursor-pointer"
            ><p class="text-blue-500">
              {{ prefix + "click-to-upload" | translate }}
            </p>
            {{ prefix + "or-drag-and-drop" | translate }}</span
          >
          <span *ngIf="uploading" class="text-gray-500 text-[14px]">{{
            prefix + "uploading" | translate
          }}</span>
          <input
            id="file-uploads"
            type="file"
            (change)="onFileSelected($event)"
            accept=".jpg, .jpeg, .png, .mp4"
            multiple
            hidden
          />
        </label>
        <p class="flex flex-col items-center text-gray-400 text-[14px]">
          Accepts MP4, JPG or PNG
        </p>
      </div>
      <p
        class="text-[14px] text-gray-500 bg-gray-100 rounded-xl px-2 w-full h-7 justify-start items-center flex gap-2"
      >
        <i class="sctr-icon-info-circle"></i>
        {{ prefix + "recommended-image-ratios-blog" | translate }}
      </p>
    </div>
  </div>
</div>
