import { CommonModule } from '@angular/common';
import { NgModule, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { LandingPageContentComponent } from './landing-page-content.component';
import { LandingPageContentRoutingModule } from './landing-page-content-routing.module';
import { HeroSectionComponent } from './component/hero-section/hero-section.component';
import { BlogSectionComponent } from './component/blog-section/blog-section.component';
import { AdventureStylesComponent } from './component/adventure-styles/adventure-styles.component';
import { ExplorePlacesComponent } from './component/explore-places/explore-places.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ImagePathPipe } from '@core/pipes/image-path.pipe';
import { CarouselModule } from 'primeng/carousel';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';
import { BrowserModule } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { TruncateStringPipe } from '@core/pipes/truncate-string.pipe';
@NgModule({
  declarations: [
    LandingPageContentComponent,
    HeroSectionComponent,
    BlogSectionComponent,
    AdventureStylesComponent,
    ExplorePlacesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    LandingPageContentRoutingModule,
    DragDropModule,
    InputSwitchModule,
    ImagePathPipe,
    CarouselModule,
    DropdownModule,
    TruncateStringPipe,
  ],
  providers: [SearchingPlaceControllerService],
})
export class LandingPageContentModule {}
